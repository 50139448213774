<template>
    <div class="d-flex align-items-center min-vh-100">
        <CContainer fluid>
            <CRow class="justify-content-center">
                <CCol md="4">
                    <CCard class="mx-4 mb-0">
                        <CCardBody class="p-4">
                            <CForm v-show="registration_form" @keyup.enter="validator();sign_up()">
                                <h2>Create an Account</h2>
                                <p class="text-muted">Please fill out the Registration Form</p>
                                <CInput v-model="form.username.val" :description="form.username.inv" label="E-mail" />
                                <CInput v-model="form.password.val" :description="form.password.inv" label="Password" type="password" />
                                <CInput v-model="form.repeat_password.val" :description="form.repeat_password.inv" label="Confirm Password" type="password" />
                                <CRow>
                                    <CCol sm="6" class="text-left">
                                        <CButton @click="sign_up" color="primary" block>Submit</CButton>
                                    </CCol>
                                    <CCol sm="6" class="text-right">
                                        <CButton @click="redirect_to('/signin')" color="link" class="px-0">Login</CButton>
                                    </CCol>
                                </CRow>
                            </CForm>
                            <CForm v-show="verification_form" @keyup.enter="validator();verify()">
                                <h2>Create an Account</h2>
                                <p class="text-muted">Enter Verification Code.</p>
                                <CInput v-model="form.verification_code.val" :description="form.verification_code.inv" label="Verification Code " />
                                <CRow>
                                    <CCol sm="6" class="text-left">
                                        <CButton @click="verify" color="primary" block>Submit</CButton>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                username: {val: '', inv: '', v: {req: true, max: 1024, spec: 'email'}},
                password: {val: '', inv: '', v: {req: true, max: 1024, spec: 'password'}},
                repeat_password: {val: '', inv: '', v: {req: true, max: 1024, spec: 'repeat_password'}},
                verification_code: {val: '', inv: '', v: {req: true, max: 1024}},
            },
            disable: false,
            registration_form: true,
            verification_form: false,
            perms:{
                'arbitrage.DataSourceLogs': {delete: false, get: false, post: false, put: false},
                'arbitrage.DataSources': {delete: false, get: false, post: false, put: false},
                'arbitrage.InputDatas': {delete: false, get: false, post: false, put: false},
                'arbitrage.InputProccess': {delete: false, get: false, post: false, put: false},
                'arbitrage.InputProccessTriggers': {delete: false, get: false, post: false, put: false},
                'arbitrage.OutputDatas': {delete: false, get: false, post: false, put: false},
                'arbitrage.SystemCustomValues': {delete: false, get: false, post: false, put: false},
                'system.GuestUserTokens': {delete: false, get: false, post: false, put: false},
                'system.TelegramAccounts': {delete: false, get: false, post: false, put: false},
                'system.User': {delete: false, get: false, post: false, put: false},
                'system.UserGroup': {delete: false, get: false, post: false, put: false},
                'system.UsersToUserGroup': {delete: false, get: false, post: false, put: false},
            },
        }
    },
    methods: {
        validator() {
            for (const key in this.form){
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form){
                for (const k in this.form[key].v){
                    switch(k){
                        case 'req':
                            if(this.form[key].val == ''){
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if(this.form[key].val != ''){
                                if(this.form[key].val.length > this.form[key].v[k]){
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'spec':
                            switch(this.form[key].v[k]){
                                case 'email':
                                    if (this.form[key].val != '' && this.form[key].inv == '') {
                                        const re_email = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                                        if (!re_email.test(String(this.form[key].val).toLowerCase())) {
                                            this.form[key].inv = 'E-mail is not valid.';
                                            this.disable = true;
                                        }
                                    }
                                    break;
                                case 'password':
                                    if(this.form[key].val != '' && this.form[key].inv == ''){
                                        const re_password = new RegExp(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
                                        if (!re_password.test(String(this.form[key].val))) {
                                                                       this.form[key].inv = 'Must be at least 8 characters and contain numbers, uppercase and lowercase letters.';
                                            this.disable = true;
                                        }
                                    }
                                    break;
                                case 'repeat_password':
                                    if(this.form[key].val != '' && this.form[key].inv == ''){
                                        if(this.form[key].val != this.form.password.val){
                                            this.form[key].inv = 'Password and confirm password does not match.';
                                            this.disable = true;
                                        }
                                    }
                                    break;
                            }
                            break;
                    }
                }
            }
        },
        sign_up() {
            if(this.disable == false){
                const data = new FormData();
                data.set('username', this.form.username.val);
                data.set('password', this.form.password.val);
                this.axios.post('signup', data)
                    .then(res => {
                        if (res.data.state == 'user_exists') {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                text: 'User exists with the given E-mail.'
                            });
                        } else if (res.data.state == 'send_verification_code') {
                            this.$notify({
                                group: 'foo',
                                type: 'success',
                                text: 'Verification code sent.'
                            });
                            this.registration_form = false;
                            this.verification_form = true;
                        }
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    });
            }
        },
        verify() {
            if(this.disable == false){
                const data = new FormData();
                data.set('username', this.form.username.val);
                data.set('verification_code', this.form.verification_code.val);
                this.axios.post('signup', data)
                    .then(res => {
                        if (res.state == 'check_verification_code') {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                text: 'Verification code is invalid.'
                            });
                        } else {
                            this.$notify({
                                group: 'foo',
                                type: 'success',
                                text: 'Submitted Successfully.',
                            });
                            let d = new Date();
                            d.setDate(d.getDate() + 1);
                            this.$store.commit('set_expires', d);
                            this.$store.commit('set_token', `Bearer ${res.data.token}`);
                            for(const x in res.data.user_info.user_perm_array){
                                for(const y in this.perms){
                                    if(x == y){
                                        for(const i in res.data.user_info.user_perm_array[x]){
                                            for(const j in this.perms[y]){
                                                if(i == j){
                                                    this.perms[y][j] = res.data.user_info.user_perm_array[x][i];
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            this.$store.commit('set_perm', this.perms);
                            this.$store.commit('set_user_id', res.data.user_info.id);
                            const styles = localStorage.getItem('styles');
                            if(!styles){
                                localStorage.setItem('styles', JSON.stringify([]));
                            }
                            if (this.$route.query.redirect == undefined) {
                                this.$router.push('/dashboard/sort_num/asc');
                            } else {
                                this.$router.push(this.$route.query.redirect);
                            }
                        }
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    });
            }
        },
        redirect_to(route) {
            this.$router.push(route);
        },
    }
}
</script>
